<template>
  <ag-modal-container
    :value="visible"
    :title="$t('multiproject.feverchart.title')"
    width="600px"
    @close="close"
  >
    <v-container class="text-center">
      <v-btn-toggle v-model="type" borderless>
        <v-btn value="line">
          <span v-text="$t('multiproject.feverchart.mono')" />
        </v-btn>
        <v-btn value="scatter">
          <span v-text="$t('multiproject.feverchart.multi')" />
        </v-btn>
      </v-btn-toggle>

      <ag-fever-chart
        :type="type"
        :value="game.playerList"
        :size="{ height: 300, width: 500 }"
        show-legend
      />
    </v-container>

    <template v-slot:footer>
      <v-spacer />
      <v-btn
        color="primary"
        class="ml-5"
        outlined
        @click="close"
        v-text="$t('general.close')"
      />
    </template>
  </ag-modal-container>
</template>

<script>
import AgModalContainer from 'src/components/common/AgModalContainer'
import AgFeverChart from 'src/components/common/AgFeverChart'

export default {
  name: 'FeverChartContainer',
  components: {
    AgModalContainer,
    AgFeverChart,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    game: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    type: 'scatter',
  }),
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
  },
}
</script>
