<template>
  <div ref="chart" :style="style" />
</template>

<script>
import c3 from 'c3'
import 'c3/c3.css'
import _map from 'lodash/map'
import _keyBy from 'lodash/keyBy'
import _mapValues from 'lodash/mapValues'
import _last from 'lodash/last'
import Helpers from 'src/utils/helpers'

export default {
  name: 'AgFeverChart',
  props: {
    value: {
      type: [Array, Object],
      default: () => ([]),
    },
    type: {
      type: String,
      default: 'line',
      validator: value => ['line', 'scatter'].includes(value),
    },
    size: {
      type: Object,
      default: null,
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    idKey: {
      type: String,
      default: 'id',
    },
    nameKey: {
      type: String,
      default: 'projectName',
    },
    pointListKey: {
      type: String,
      default: 'feverChartItemList',
    },
    xKey: {
      type: String,
      default: 'progressionPercentage',
    },
    yKey: {
      type: String,
      default: 'bufferConsumption',
    },
  },
  data: () => ({
    chart: null,
  }),
  computed: {
    style () {
      return this.size
        ? { width: `${this.size.width}px`, height: `${this.size.height}px` }
        : {}
    },
    projects () {
      return Array.isArray(this.value) ? this.value : [this.value]
    },
    baseData () {
      return {
        xs: _mapValues(_keyBy(this.$options.areaList, 'name'), () => 'x'),
        columns: [
          ['x', 0, 100],
          ...this.$options.areaList.map(item => ([item.name, ...item.ordinateList])),
        ],
        types: _mapValues(_keyBy(this.$options.areaList, 'name'), () => 'area'),
        names: {},
        groups: [_map(this.$options.areaList, 'name')],
        order: null,
      }
    },
    projectDataList () {
      return this.projects
        .filter(project => project[this.pointListKey].length)
        .map(project => ({
          id: project[this.idKey],
          name: project[this.nameKey],
          xList: _map(project[this.pointListKey], this.xKey),
          yList: _map(_map(project[this.pointListKey], this.yKey), this.getScaledValue),
        }))
    },
    data () {
      return this.projectDataList.reduce((data, project) => {
        data.xs[project.id] = `x${project.id}`
        data.types[project.id] = this.type
        data.names[project.id] = project.name

        if (this.type === 'line') {
          data.columns.push([data.xs[project.id], ...project.xList])
          data.columns.push([project.id, ...project.yList])
        }
        else {
          data.columns.push([data.xs[project.id], _last(project.xList)])
          data.columns.push([project.id, _last(project.yList)])
        }

        return data
      }, Helpers.clone(this.baseData))
    },
    legendOptions () {
      return {
        show: this.showLegend,
        hide: _map(this.$options.areaList, 'name'),
      }
    },
    yMax () {
      const lowestYMax = 110
      return Math.max(lowestYMax, ..._map(this.projectDataList, 'yList'))
    },
    axisOptions () {
      return {
        x: { show: false, max: 100, padding: 10 },
        y: { show: false, max: this.yMax, padding: 10 },
      }
    },
    tooltipOptions () {
      return {
        grouped: false,
        contents: ([data]) => this.hasTooltipContent(data)
          && this.getTooltipContent(data),
      }
    },
    chartOptions () {
      return {
        bindto: this.$refs?.chart,
        size: this.size,
        data: this.data,
        point: { r: 5 },
        legend: this.legendOptions,
        axis: this.axisOptions,
        padding: { right: 10 },
        color: {
          pattern: [
            ..._map(this.$options.areaList, 'color'),
            ...(new Array(this.projects.length)).fill('black'),
          ],
        },
        transition: { duration: 1000 },
        tooltip: this.tooltipOptions,
      }
    },
  },
  watch: {
    value () {
      this.refreshDataChart()
    },
    type () {
      this.renderChart()
    },
  },
  async mounted () {
    setTimeout(() => this.renderChart(), 0)
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.destroy()
    }
  },
  methods: {
    renderChart () {
      if (this.chart) {
        this.chart.destroy()
      }
      this.chart = c3.generate(this.chartOptions)
    },
    refreshDataChart () {
      if (this.chart) {
        this.chart.load(this.data)
      }
      else {
        this.renderChart()
      }
    },
    getScaledValue (value) {
      return value > 100
        ? 100 + (value - 100) / 10
        : value
    },
    getUnscaledValue (value) {
      return value > 100
        ? 100 + (value - 100) * 10
        : value
    },
    hasTooltipContent (data) {
      return !_map(this.$options.areaList, 'name').includes(data.id) || undefined
    },
    getTooltipContent (data) {
      return `<div class="fc-tooltip">
        <p class="project-name">${data.name}</p>
        <p>${Math.round(data.x)}% ${this.$t('multiproject.feverchart.progress')}</p>
        <p>${Math.round(this.getUnscaledValue(data.value))}% ${this.$t('multiproject.feverchart.buffer')}</p>
      </div>`
    },
  },
  areaList: [
    { name: 'green', color: '#23b145', ordinateList: [10, 70] },
    { name: 'yellow', color: '#f9ed22', ordinateList: [20, 20] },
    { name: 'red', color: '#e03333', ordinateList: [70, 10] },
    { name: 'black', color: '#000000', ordinateList: [10, 10] },
  ],
}
</script>

<style>
.c3-circle{
  stroke: white !important;
}

.c3-line-black,
.c3-line-red,
.c3-line-yellow,
.c3-line-green {
  opacity: 0 !important;
}

.c3-circles-black,
.c3-circles-red,
.c3-circles-yellow,
.c3-circles-green {
   display: none !important;
}

.c3-target .c3-area {
  opacity: 0.8 !important;
}
.fc-tooltip{
  background-color: #ffffffd9;
  padding: 10px;
}
.fc-tooltip p{
  margin-bottom: 0;
}
.fc-tooltip .project-name{
  font-weight: bold;
  color: black;
}
</style>
