<template>
  <div
    :class="`float-action-button-${left ? 'left' : 'right'}`"
    class="float-action-button"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AgFloatButtonContainer',
  props: {
    left: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.float-action-button {
  position: fixed;
  bottom: 10px;
}

.float-action-button-left {
  left: 10px;
}

.float-action-button-right {
  right: 10px;
}

.float-action-button * {
  margin: 8px;
}
</style>
